import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[uiA11yElementClick]',
  standalone: true,
})
export class A11yElementClickDirective {
  @Output()
  public uiA11yElementClick: EventEmitter<MouseEvent | KeyboardEvent> =
    new EventEmitter<MouseEvent | KeyboardEvent>();

  @HostListener('click')
  public onClick(e: MouseEvent): void {
    this.uiA11yElementClick.emit(e);
  }

  @HostListener('keydown.enter')
  public onEnter(e: KeyboardEvent): void {
    this.uiA11yElementClick.emit(e);
  }
}
